const messages = {
  // Profile
  my_profile: "Mein Profil",
  role: "Rolle",
  company: "Unternehmen",
  name: "Name",
  country: "Land",
  contact_number: "Kontakt Nummer",
  sign_out: "Ausloggen",
  edit_profile: "Profil bearbeiten",
  change_password: "Kennwort ändern",
  profile_info: "Profil Information",
  log_out: "Ausloggen",
  confirm_logout: "Möchten Sie sich wirklich abmelden?",

  // Users
  add_user: "Benutzer hinzufügen",
  update_profile_info: "Profilinformationen aktualisieren",
  update_user: "Benutzer aktualisieren",
  deactivate: "Deaktivieren",
  reactivate: "Reaktivieren",
  deactivate_user: "Benutzer deaktivieren",
  excel_filename_user: "Benutzerbericht",
  select_country_code: "Wählen Sie den Ländercode aus",
  enter_company_name: "Geben Sie den Firmennamen ein",
  password: "Passwort",
  enter_password: "Geben Sie das Passwort ein",

  // User Table
  first_name: "Vorname",
  last_name: "Familienname",
  email: "Email",

  // Roles
  ADMIN: "Administrator",
  OPERATOR: "Operator",

  // success messages
  user_deactivated_success: "Benutzer erfolgreich deaktiviert",
  new_user_added: "Neuer Benutzer hinzugefügt",
  update_user_profile_success:
    "Ihre Informationen wurden erfolgreich aktualisiert.",
};

export default messages;
