import { useContext } from "react";
import { ConfigProvider, Select, SelectProps, Space } from "antd";
import styled from "styled-components";
import { DarkModeContext } from "../../providers/DarkModeProvider";
import { TickIcon, DownSingleArrowIcon } from "../../icons";

const StyledSelect = styled(Select)``;

const SelectedLabel = styled.span<{ variant: SelectProps["variant"] }>`
  font-weight: ${({ variant }) => (variant === "borderless" ? 600 : 400)};
  color: ${({ color }) => color};
  padding-right: 8px;
`;

const SingleSelect = ({
  placeholder = "",
  value = null,
  variant = "outlined",
  style = { width: 324 },
  onChange = () => {},
  popupMatchSelectWidth = 324,
  listHeight = 260,
  options = [],
  error = [],
  showSearch = false,
}: {
  placeholder?: string;
  value?: string;
  variant?: SelectProps["variant"];
  style?: React.CSSProperties;
  onChange?: (value: unknown) => void;
  popupMatchSelectWidth?: number;
  listHeight?: number;
  options: SelectProps["options"];
  error?: string[] | undefined;
  showSearch?: boolean;
}) => {
  const { theme } = useContext<any>(DarkModeContext);
  const { token } = theme;

  return (
    <ConfigProvider theme={theme}>
      <StyledSelect
        status={error && error.length > 0 ? "error" : undefined}
        placeholder={placeholder}
        options={options}
        value={value}
        style={style}
        variant={variant}
        popupMatchSelectWidth={popupMatchSelectWidth}
        listHeight={listHeight}
        onSelect={onChange}
        showSearch={showSearch}
        filterOption={(input, option) => {
          return (option?.label ?? "")
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        suffixIcon={<DownSingleArrowIcon style={{ height: 20, width: 20 }} />}
        labelRender={({ label }) => {
          return (
            <SelectedLabel
              variant={variant}
              color={token.datwylerText.textPrimary}
            >
              {label}
            </SelectedLabel>
          );
        }}
        optionRender={({ label, value: optionValue }) => {
          return (
            <Space style={{ display: "flex", justifyContent: "space-between" }}>
              {label}
              {optionValue === value && (
                <TickIcon style={{ color: token.datwylerIcon.iconBrand }} />
              )}
            </Space>
          );
        }}
      />
    </ConfigProvider>
  );
};

export default SingleSelect;
