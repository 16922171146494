const messages = {
  // Profile
  my_profile: "My Profile",
  role: "Role",
  company: "Company",
  name: "Name",
  country: "Country",
  contact_number: "Contact Number",
  sign_out: "Sign out",
  edit_profile: "Edit Profile",
  change_password: "Change password",
  profile_info: "Profile Information",
  log_out: "Log out",
  confirm_logout: "Are you sure you want to log out?",

  // Users
  add_user: "Add User",
  update_profile_info: "Update Profile Information",
  update_user: "Update User",
  deactivate: "Deactivate",
  reactivate: "Reactivate",
  deactivate_user: "Deactivate User",
  excel_filename_user: "Users Report",
  select_country_code: "Select Country Code",
  enter_company_name: "Enter Company Name",
  password: "Password",
  enter_password: "Enter Password",

  // User Table
  first_name: "First Name",
  last_name: "Last Name",
  email: "Email",

  // Roles
  ADMIN: "Admin",
  OPERATOR: "Operator",

  // success messages
  user_deactivated_success: "User(s) Deactivated Successfully",
  new_user_added: "New User Added",
  update_user_profile_success: "Successfully updated your info.",
};

export default messages;
