const messages = {
  // Profile
  my_profile: "Mon Profil",
  role: "Rôle",
  company: "Entreprise",
  name: "Nom",
  country: "Pays",
  contact_number: "Numéro de contact",
  sign_out: "Se déconnecter",
  edit_profile: "Modifier le profil",
  change_password: "Changer le mot de passe",
  profile_info: "Informations sur le profil",
  log_out: "Se déconnecter",
  confirm_logout: "Êtes-vous sûr de vouloir vous déconnecter?",

  // Users
  add_user: "Ajouter un utilisateur",
  update_profile_info: "Mettre à jour les informations du profil",
  update_user: "Mettre à jour l'utilisateur",
  deactivate: "Désactiver",
  reactivate: "Réactiver",
  deactivate_user: "Désactiver l'utilisateur",
  excel_filename_user: "Rapport des utilisateurs",
  select_country_code: "Sélectionnez le code du pays",
  enter_company_name: "Entrez le nom de l'entreprise",
  password: "Mot de passe",
  enter_password: "Entrez le mot de passe",

  // User Table
  first_name: "Prénom",
  last_name: "Nom de famille",
  email: "E-mail",

  // Roles
  ADMIN: "Administrateur",
  OPERATOR: "Opérateur",

  // success messages
  user_deactivated_success: "Utilisateur(s) désactivé(s) avec succès",
  new_user_added: "Nouvel utilisateur ajouté avec succès",
  update_user_profile_success: "Vos informations ont bien été mises à jour.",
};

export default messages;
